@tailwind base;
@tailwind components;
@tailwind utilities;

/* Optional: PAGINATION BORDER */
.ant-pagination-item-active a {
  color: #111827 !important;
}

/* Optional: BORDER SELECT */
.ant-select-focused .ant-select-selector {
  box-shadow: 0 0 0 2px rgba(255, 87, 34, 0.2) !important;
  outline: none !important;
}

/* Optional: TABLE */
.ant-table-thead > tr > th {
  background-color: #F3F4F6 !important;
}

.ant-table-footer {
  background-color: #FFFFFF !important;
}

.ant-breadcrumb-link a {
  @apply !sl-font-semibold;
}

.items-table .ant-table-footer {
  @apply !sl-bg-surface-primary-light;
}

.invoice-items-table .ant-table-footer {
  @apply !sl-p-0;
}

.result-table .ant-table-thead > tr > th {
  @apply !sl-text-xs;
}

.result-table .ant-table-tbody > tr > td {
  @apply !sl-text-xs;
}

.scroll-modal .ant-modal-body {
  height: 568px !important; /* Adjust the height as needed */
  max-height: 568px !important; /* Adjust the height as needed */
  overflow-y: scroll; /* Enable vertical scrolling */
  @apply sl-h-[568px] sl-max-h-[568px] sl-overflow-y-scroll;
}

.scroll-modal .ant-modal-content {
  @apply !sl-px-0;
}

.scroll-modal .ant-modal-header {
  @apply !sl-px-6;
}

.scroll-modal .ant-modal-body {
  @apply !sl-pl-6;
}

.invoice-items-footer > tbody > tr > td {
  @apply !sl-p-4;
}

.custom-range-picker.hide-enddate .ant-picker-range-separator{
  display: none !important;
}

.custom-range-picker.hide-enddate{
  height: 33px;
}

.custom-range-picker.hide-enddate :nth-child(3){
  width: 0;
}


.custom-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}
